import {defineStore} from "pinia";
import supabase from "../supabase/supabase.ts";
import {ref} from "vue";
import emailjs from "@emailjs/browser";
import {Credentials} from "../types.ts";

export const useConfigStore = defineStore("config", () => {
    const transition = ref<string>("transition-all ease-in");

    const showToast = ref<boolean>(false)
    const toastMsg = ref<string>("")

    const getProj = async () => {
        return supabase.from('Projects').select('*').order(`id`, {ascending: true});
    }
    const getSingleProj = async(id: number) => {
        return supabase.from('Projects').select('*').eq('id', id);
    }

    const sentEmail = (credentials: Credentials, form: any) => {
        const {fullname, email, textbox} = credentials;
        if (fullname === ""  || email === "" || (textbox === "" || (textbox.length<15 || textbox.length>100))) return ;
        emailjs.sendForm(import.meta.env.VITE_EMAIL_SERVICE, import.meta.env.VITE_EMAIL_TEMPLATE, form, {
            publicKey: import.meta.env.VITE_EMAIL_KEY,
        })
            .then(
                () => {
                    showToast.value = true
                    toastMsg.value = "Email sent Successfully!"
                },
                () => {
                    showToast.value = true
                    toastMsg.value = "Error while sending the email"
                },
            );
    }

    const openMenu = ref<boolean>(false);

    return {getProj, getSingleProj, openMenu, sentEmail, transition, showToast, toastMsg}
})