import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import {useConfigStore} from "../stores/config.ts";


const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: `Homepage`,
        component: () => import('../views/Home.vue')
    },
    {
      path: `/projs`,
      children: [
          {
              path: ``,
              name: `OverView`,
              component: () => import('../views/projects/Overview.vue')
          },
          {
              path: `:id([0-9]+)`,
              name: `Project`,
              component: () => import('../views/projects/Project.vue')
          }
      ],
        component: () => import('../views/Projects.vue'),
    },
    {
        path: `/certs`,
        name: `Certificates`,
        component: () => import('../views/Achievements.vue')
    },
    {
        path: `/comn`,
        name: `Contact`,
        component: () => import('../views/Contact.vue')
    },
    {
      path:`/:catchAll(.*)*`,
      name: `404Page`,
      component: () => import('../views/Error.vue')
    }
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
})

router.beforeEach(() => {
        const config = useConfigStore();
        config.openMenu = false;
}
)


export default router